.form-field {
  @apply grid;
  @apply mt-2;
  // @apply pb-2;
  grid-template-columns: 150px auto;
  .field-title-container {
    overflow: hidden;
    p {
      padding: 0;
      margin: 0;
      font-size: 14px;
      color: rgb(var(--dark-color));
      padding-top: 6px;
    }
    span {
      display: block;
      margin-top: 8px;
      font-size: 10px;
      color: rgba(var(--dark-color), 0.6);
    }
  }

  .form-field-container {
    button {
      margin-top: 16px;
      max-width: 120px;
    }
  }

  button {
    max-width: 200px;
    width: auto;
  }
}
