// @tailwind base;
// @tailwind components;
// @tailwind utilities;

@import 'tailwindcss/base' layer(tailwindcss);
@import 'tailwindcss/components' layer(tailwindcss);
@import 'tailwindcss/utilities' layer(tailwindcss);

@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';

@import './styles/variables.scss';
@import './styles/form.scss';
@import './styles/ck-editor.scss';

html,
body {
	height: 100%;
	background: #f5f5f5;
}
body {
	margin: 0;
	font-family: var(--font-family);
}

// Scrollbar Style

body {
	--sb-track-color: #f1f1f1;
	--sb-thumb-color: #c4c4c4;
	--sb-size: 5px;
}

body::-webkit-scrollbar {
	width: var(--sb-size);
}

body::-webkit-scrollbar-track {
	background: var(--sb-track-color);
	border-radius: 3px;
}

body::-webkit-scrollbar-thumb {
	background: var(--sb-thumb-color);
	border-radius: 3px;
}

@supports not selector(::-webkit-scrollbar) {
	body {
		scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
	}
}

// Scrollbar style end

.file-input {
	display: none;
	opacity: 0;
	position: absolute;
	left: -99999px;
	top: -99999px;
	visibility: hidden;
}
.add-image {
	width: 100px;
	height: 100px;
	object-fit: cover;
	border-radius: 8px;
	cursor: pointer;
}

.image-container {
	margin-right: 16px;
	display: flex;
	flex-wrap: wrap;
	.file-input {
		display: none;
		opacity: 0;
		position: absolute;
		left: -99999px;
		top: -99999px;
		visibility: hidden;
	}
	.add-image {
		width: 100px;
		height: 100px;
		object-fit: cover;
		border-radius: 8px;
		opacity: 0.2;
		cursor: pointer;
	}
	.selected-image-container {
		position: relative;
		cursor: pointer;
		img {
			width: 100px;
			height: 100px;
			object-fit: cover;
			margin-right: 16px;
			border-radius: 8px;
			margin-bottom: 8px;
		}

		.remove-button {
			position: absolute;
			top: -10px;
			right: 2px;
			margin: 0;
			padding: 0;
			height: unset;
			width: unset;
			// background: white;
			border: none;
			outline: none;
			span {
				padding-right: 0px;
			}
		}
	}
}

.ck-editor__editable_inline {
	max-height: 200px;
}
.ck-editor__editable {
	max-height: 200px;
	overflow-y: auto;
}
//*
.p-datatable-table th,
.p-datatable-table tr:nth-child(even) {
	//   background-color: #4da31414;
	// @apply bg-[#4da31414];
}

.p-datatable .p-datatable-thead > tr > th {
	@apply text-[13px] font-semibold uppercase;
	padding: 0.6rem 0.4rem;
	border-left: 3px solid white;
	border-right: 3px solid white;
	//   width: 100%;
}

.p-datatable-table tr {
	@apply cursor-pointer;
}

.p-datatable td {
	padding: 0.4rem;
	//   @apply text-base;
	border-left: 3px solid white;
	border-right: 3px solid white;

	.p-button.p-button-icon-only.p-button-rounded {
		@apply p-0 h-[initial] w-auto outline-none border-none shadow-none;
	}
}

.p-inputtext,
p-inputnumber,
.p-inputnumber,
.p-calendar {
	@apply w-full;
}

p-dropdown .p-dropdown {
	@apply w-full;
}
.input-error {
	@apply text-red-600;
	@apply font-medium;
	@apply text-base;
}

.p-inputtext.ng-touched.ng-invalid,
p-dropdown.ng-touched.ng-invalid,
p-calendar.ng-touched.ng-invalid,
p-autocomplete.ng-touched.ng-invalid,
p-inputmask.ng-touched.ng-invalid,
p-inputnumber.ng-touched.ng-invalid {
	border: 1px solid #e24c4c !important;
}

.p-inputtext,
// p-dropdown,
.p-button.p-button-icon-only {
	padding: 0.4rem !important;
	font-size: 16px;
}

.p-input-icon-left > .p-inputtext {
	padding-left: 2.5rem !important;
}
//*
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
	@apply h-8 md:h-10 min-w-[2rem] md:min-w-[2rem];
}

.p-paginator .p-paginator-pages .p-paginator-page {
	min-width: 2.5rem;
	height: 2.5rem;
	font-size: 14px;
}

button.p-button {
	@apply p-2 text-sm;
}

button.p-button > span {
	@apply text-lg leading-[1];
}

.p-accordion .p-accordion-header .p-accordion-header-link {
	@apply py-2 px-2 text-base;
}
