:root {
  --bg-color: 248, 249, 250;
  --dark-color: 22, 26, 29;
  --main-color: #d3ff67;
  --white: #fff;
  --border-radius: 8px;
  --font-family: "Poppins", "Helvetica Neue", sans-serif;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 60px 15px;
  --box-shadow-lighter: rgba(149, 157, 165, 0.05) 0px 1px 3px 2px;
  --border: 1px solid rgba(var(--dark-color), 0.08);
}
